import { useEffect, useRef, useState } from "react";
import { DownloadTableExcel } from "react-export-table-to-excel";

import XLSX from "xlsx";
import { make_cols } from "./MakeColumns";
import { SheetJSFT, elements } from "./types";
import { Button, Form, Input, InputNumber, Modal, Select, Skeleton } from "antd";
import Title from "antd/es/typography/Title";

const ExcelReader = () => {
  const [state, setState] = useState<any>({
    file: null,
    data: [],
    cols: [],
  });
  const [isLoading, setLoading] = useState<boolean>(false);
  const [keys, setKeys] = useState<string[]>([]);
  const [mapping, setMapping] = useState<any[]>([]);
  const [mappedData, setMappedData] = useState<any[]>([]);
  const [openModal, setIsModalOpen] = useState<boolean>(false);
  const [numLigne, setNumLigne] = useState<number>(0);
  const [numPiece, setNumPiece] = useState<number>(0);
  const tableRef = useRef(null);

  useEffect(() => {
    const list: any[] = [];
    elements.map((el) => {
      list.push({ name: el, value: "", isDefault: true });
    });
    setMapping(list);
  }, []);
  const handleChange = (e: any) => {
    const files = e.target.files;
    if (files && files[0]) setState({ file: files[0] });
  };

  const handleFile = (e: any) => {
    /* Boilerplate to set up FileReader */
    setLoading(true);

    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;
    setKeys([]);

    reader.onload = (e) => {
      /* Parse data */
      const bstr = e.target!.result;
      const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array", bookVBA: true });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_json(ws);
      /* Update state */
      setState({ data: data, cols: make_cols(ws["!ref"]) });
      var l__keys: string[] = [];
      data.forEach((el: any) => {
        if (Object.keys(el).length > l__keys.length) {
          l__keys = Object.keys(el);
        }
      });

      setKeys(l__keys);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    };

    if (rABS) {
      reader.readAsBinaryString(state.file);
    } else {
      reader.readAsArrayBuffer(state.file);
    }
  };

  const generateFile = () => {
    var mappedData: any[] = [];
    let numL = 2;

    state.data.forEach((el: any) => {
      var json: any = {};
      elements.forEach((x) => {
        if (x == "Num Ligne") {
          json[x] = numL;
          numL++;
        } else {
          json[x] = getValue(x, el);
        }
      });
      mappedData.push(json);
    });
    setMappedData(mappedData);
    setIsModalOpen(false);
  };

  const toVerifyJson = (json: any) => {
    let res = false;
    elements.forEach((x) => {
      if (json[x] != undefined && json[x] != "") {
        res = true;
      }
    });
    return res;
  };

  const getValue = (x: string, element: any): string => {
    const searched = mapping.filter((el) => el.name === x)[0];
    return searched.isDefault ? searched.value : element[searched.value];
  };

  const updateMapping = (key: number, isDefault: boolean, value: string) => {
    const list = [...mapping];
    list[key].isDefault = isDefault;
    list[key].value = value;
    setMapping(list);
  };

  const onChange = (value: number | null) => {
    setNumPiece(value ?? 0);
  };

  const onChangeLigne = (value: number | null) => {
    setNumLigne(value ?? 0);
  };

  return (
    <div>
      <Title level={5}>Mapping de données</Title>
      <table>
        <tr>
          <td>
            <Input type='file' id='file' accept={SheetJSFT} onChange={(e) => handleChange(e)} />
          </td>

          <td>
            <Button
              type='primary'
              disabled={state.file == null}
              onClick={(e) => {
                setIsModalOpen(true);
                handleFile(e);
              }}>
              Valider
            </Button>
          </td>
        </tr>
      </table>
      <Skeleton loading={isLoading} active />
      {isLoading && (
        <div style={{ textAlign: "center" }}>
          <img src='/images/ball.gif' />
        </div>
      )}
      <Modal
        className='smtv-modal'
        width={1200}
        open={openModal}
        onCancel={() => setIsModalOpen(false)}
        onOk={() => generateFile()}
        okText='Générer le fichier'>
        {isLoading ? (
          <div style={{ textAlign: "center" }}>
            <p>Attendez SVP...</p>
            <div style={{ textAlign: "center" }}>
              <img src='/images/ball.gif' />
            </div>
          </div>
        ) : (
          <>
            <table className='ant-table' style={{ width: "100%" }}>
              <tr>
                {keys.map((el) => (
                  <th>{el}</th>
                ))}
              </tr>
              {state.data &&
                state.data.map((el: any) => (
                  <tr>
                    {keys.map((i) => (
                      <td>{el[i] ?? ""}</td>
                    ))}
                  </tr>
                ))}
            </table>
            {keys.length > 0 && (
              <>
                <Title level={3}>Correspondance</Title>
                <table>
                  <tr>
                    <th>Valeur fichier destination</th>
                    <th>Valeur fichier source</th>
                    <th>Fixe value</th>
                  </tr>
                  {elements
                    .filter((el) => el != "Num Ligne")
                    .map((el, key) => (
                      <tr>
                        <td style={{ width: "200px" }}>{el}</td>
                        <td style={{ width: "200px" }}>
                          <Select style={{ width: "200px" }} onChange={(e) => updateMapping(key, false, e)}>
                            {keys.map((x) => (
                              <Select.Option value={x}>{x}</Select.Option>
                            ))}
                          </Select>
                        </td>
                        <td>
                          <Input onChange={(e) => updateMapping(key, true, e.target.value)} />
                        </td>
                      </tr>
                    ))}
                </table>
              </>
            )}
          </>
        )}
      </Modal>

      <div style={{ marginTop: "20px" }}>
        <DownloadTableExcel filename='table' sheet='table' currentTableRef={tableRef.current}>
          <Button type='primary' disabled={mappedData.length === 0}>
            Exporter le résultat
          </Button>
        </DownloadTableExcel>

        <table ref={tableRef} style={{ marginTop: "20px" }}>
          <tbody>
            {mappedData.length > 0 && (
              <>
                <tr>
                  {elements.map((el, key) => (
                    <th style={{ width: "200px" }}>{el}</th>
                  ))}
                </tr>
                {mappedData.map((line, lineKey) => (
                  <tr>
                    {elements.map((el, key) => (
                      <td style={{ width: "200px" }}>{line[el]}</td>
                    ))}
                  </tr>
                ))}
              </>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ExcelReader;
