export const SheetJSFT = [
  "xlsx",
  "xlsb",
  "xlsm",
  "xls",
  "xml",
  "csv",
  "txt",
  "ods",
  "fods",
  "uos",
  "sylk",
  "dif",
  "dbf",
  "prn",
  "qpw",
  "123",
  "wb*",
  "wq*",
  "html",
  "htm",
]
  .map(function (x) {
    return "." + x;
  })
  .join(",");

export const elements = [
  "Num Pièce",
  "Compte",
  "Contrepartie",
  "Libellé",
  "Sens",
  "Mnt Origine",
  "Mnt USD",
  "Code Jrl",
  "Date Opér.",
  "Num Facture",
  "Num Ligne",
];

export const elementsBanque = ["Date opération", "Num Doc", "Code Opération", "Libellé", "Débit", "Crédit", "Date Valeur", "Devise"];
