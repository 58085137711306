import { Tabs, TabsProps } from "antd";
import "./App.css";
import ExcelReader from "./ExcelReader";
import ExcelReaderBank from "./ExcelReaderBank";

function App() {
  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Achat & vente",
      children: <ExcelReader />,
    },
    {
      key: "2",
      label: "Banque",
      children: <ExcelReaderBank />,
    },
  ];

  return (
    <div className='App'>
      <Tabs defaultActiveKey='1' items={items} />
    </div>
  );
}

export default App;
